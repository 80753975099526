.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #f0f4f7;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
    width: 450px;
    font-family: 'Roboto', sans-serif;
}

.popup-content h2 {
    margin-bottom: 25px;
    font-size: 28px;
    color: #00263a;
    text-align: center;
    font-weight: 600;
}

.popup-content form label {
    margin-bottom: 15px;
    font-weight: 500;
    color: #004b75;
    display: block;
}

.popup-content form input,
.popup-content form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    border-radius: 8px;
    border: 1px solid #b0c4d3;
    font-size: 16px;
    box-sizing: border-box;
    background: #ffffff;
    transition: border-color 0.3s;
}

.popup-content form input:focus,
.popup-content form textarea:focus {
    border-color: #006bb7;
}

.popup-content form textarea {
    resize: vertical;
    height: 120px;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.cancel-button {
    background-color: #ff7675;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #d63031;
}

.popup-actions .create-button {
    margin-left: 110px;
}

.dropdown-menu {
    background-color: white;
    border: 1px solid #b0c4d3;
    border-radius: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 160px;
    max-width: 350px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
    width: 100%;
}

.dropdown-menu li {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 15px;
}

.dropdown-menu li:hover {
    background-color: #ecf0f1;
}