.password-reset-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.password-reset-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.password-reset-container form input {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}

.password-reset-container form button {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.password-reset-container form button:hover {
    background-color: #0056b3;
}

.password-reset-container p {
    margin-top: 20px;
    color: #333;
}