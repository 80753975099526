.resumen-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    background: #f4f7f6;
}

.resumen-content {
    padding: 20px;
    flex-grow: 1;
    overflow: auto;
}

.resumen-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: left;
}

.resumen-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 20px;
}

.resumen-filter-item {
    margin-top: 8px;
    min-width: 150px;
}

.resumen-filter-apply {
    background-color: #1b2a49 !important;
    color: white !important;
    align-self: flex-end;
    margin-top: 20px !important;
}

.resumen-export-btn {
    text-decoration: none;
    padding: 6px 16px;
    margin-left: 10px;
    border-radius: 4px;
    align-self: flex-end;
    margin-top: 20px;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    min-width: 64px;
    border: none;
    cursor: pointer;
}

.resumen-export-csv {
    background-color: #1b2a49;
}

.resumen-export-csv:hover {
    background-color: #ff9f43;
    color: #1b2a49;
}

.resumen-export-excel {
    background-color: #217346;
}

.resumen-export-excel:hover {
    background-color: #28a745;
    color: white;
}

.resumen-grid-container {
    height: 400px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.resumen-stats {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.stat-card {
    flex: 1;
    min-width: 200px;
    color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.stat-card-1 {
    background: linear-gradient(135deg, #87ceeb, #00bfff);
}

.stat-card-2 {
    background: linear-gradient(135deg, #32cd32, #3cb371);
}

.stat-card-3 {
    background: linear-gradient(135deg, #ffa500, #ff7f50);
}

.stat-card-4 {
    background: linear-gradient(135deg, #d8bfd8, #dda0dd);
}

.stat-card h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
}

.stat-card p {
    margin: 0;
    font-size: 36px;
    font-weight: bold;
}

/* Responsivo para tablets y móviles */
@media (max-width: 992px) {
    .resumen-filter-item {
        min-width: 120px;
    }

    .resumen-stats {
        flex-direction: column;
        gap: 15px;
    }

    .stat-card h3 {
        font-size: 16px;
    }

    .stat-card p {
        font-size: 28px;
    }

    .resumen-content h2 {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .resumen-filter-item {
        width: 100%;
    }

    .resumen-filters {
        flex-direction: column;
    }

    .resumen-export-btn {
        margin-left: 0;
        margin-top: 10px;
    }

    .resumen-stats {
        flex-direction: column;
    }

    .stat-card p {
        font-size: 24px;
    }

    .resumen-content h2 {
        font-size: 18px;
    }

    .resumen-filter-apply {
        margin-top: 10px !important;
    }
}
