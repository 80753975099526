.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background: rgb(211, 210, 210);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

h2, h3 {
    margin-bottom: 15px;
    color: #333;
}

.search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.peritos-list, .selected-peritos {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
}

.peritos-list li, .selected-peritos li {
    padding: 8px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.selected-peritos li {
    display: flex;
    justify-content: space-between;
}

.popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.popup-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup-actions button:first-child {
    background-color: #007bff;
    color: white;
}

.popup-actions button:last-child {
    background-color: #ccc;
    color: #333;
}