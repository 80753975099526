.landing-container {
    display: flex;
    height: 100vh;
    background-color: #f0f0f0;
    overflow: hidden;
}

.left-panel {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f8f8;
    padding: 20px;
    padding-top: 50px;
}

.large-logo {
    width: 300px;
    height: auto;
    margin-bottom: 20px;
}

.welcome-text {
    font-size: 40px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
    text-align: center;
}

.welcome-subtext {
    font-size: 18px;
    color: #555;
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
}

.right-panel {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
}

.help-link {
    position: absolute;
    top: 20px;
    right: 20px;
}

.help-link a {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
}

.help-link a:hover {
    text-decoration: underline;
}

.login-dialog {
    width: 100%;
    max-width: 350px;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
    position: relative;
}

.dialog-logo {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}

.login-dialog h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.login-dialog input {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}

.form-options {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.login-dialog button {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: auto;
    margin-right: auto;
}

.login-dialog button:hover {
    background-color: #0056b3;
}

.partner-logos {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: 80px;
    background-color: transparent;
}

.partner-logos img {
    height: 125px;
    width: auto;
    background-color: transparent;
}

.forgot-password {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
}

.forgot-password:hover {
    text-decoration: underline;
}

/* Breakpoint para tablets (max-width: 992px) */
@media (max-width: 992px) {
    .landing-container {
        flex-direction: column;
        height: auto;
        overflow: auto;
    }

    .left-panel, .right-panel {
        width: 100%;
    }

    .left-panel {
        padding: 40px 20px;
    }

    .large-logo {
        width: 200px;
    }

    .welcome-text {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .welcome-subtext {
        font-size: 16px;
        max-width: 90%;
    }

    .partner-logos {
        gap: 15px;
        padding-top: 40px;
    }

    .partner-logos img {
        height: 90px;
    }

    .right-panel {
        padding: 30px 20px;
    }

    .help-link a {
        font-size: 13px;
    }

    .login-dialog {
        max-width: 300px;
        padding: 25px;
    }

    .dialog-logo {
        width: 80px;
    }

    .login-dialog h2 {
        font-size: 20px;
    }

    .login-dialog input, .login-dialog button {
        font-size: 14px;
    }
}

/* Responsive ajustes para móviles (max-width: 768px) */
@media (max-width: 768px) {
    .landing-container {
        flex-direction: column;
        height: auto;
    }

    .large-logo {
        width: 150px;
    }

    .welcome-text {
        font-size: 28px;
    }

    .welcome-subtext {
        font-size: 14px;
    }

    .login-dialog {
        width: 90%;
        margin-top: 30px;
        padding: 20px;
    }

    .dialog-logo {
        width: 60px;
        margin-bottom: 15px;
    }

    .login-dialog h2 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .login-dialog input,
    .login-dialog button {
        width: 100%;
        font-size: 14px;
    }

    .form-options {
        justify-content: center;
    }

    .partner-logos {
        gap: 10px;
        padding-top: 30px;
    }

    .partner-logos img {
        height: 60px;
    }

    .help-link {
        top: 10px;
        right: 10px;
    }
}

/* Estilos para el candado de seguridad y el tooltip */
.security-icon-container {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    z-index: 10;
}

.green-lock-icon {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.security-icon-container:hover .green-lock-icon {
    transform: scale(1.1);
}

.security-tooltip {
    position: absolute;
    top: 40px;
    right: 0;
    background: #fff;
    color: #333;
    padding: 15px;
    width: 250px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0,0,0,0.15);
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 14px;
    text-align: left;
    line-height: 1.4;
}

.security-tooltip p {
    margin: 0;
    margin-bottom: 8px;
}

.security-tooltip p:last-child {
    margin-bottom: 0;
}

.security-icon-container:hover .security-tooltip {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
}
