.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: #ffffff;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
    width: 500px;
    font-family: 'Roboto', sans-serif;
}

.popup-content h2 {
    margin-bottom: 20px;
    font-size: 26px;
    color: #00263a;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.popup-content form label {
    margin-bottom: 15px;
    font-weight: 500;
    color: #004b75;
    display: block;
    font-size: 16px;
}

.popup-content form textarea {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 10px;
    border: 1px solid #b0c4d3;
    font-size: 16px;
    box-sizing: border-box;
    background: #f9f9f9;
    transition: border-color 0.3s, box-shadow 0.3s;
    resize: vertical;
    height: 150px;
}

.popup-content form textarea:focus {
    border-color: #006bb7;
    box-shadow: 0px 0px 5px rgba(0, 107, 183, 0.3);
}

.popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.cancel-button {
    background-color: #ff6b6b;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s;
}

.cancel-button:hover {
    background-color: #e74c3c;
}

.save-button {
    background-color: #1abc9c;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #16a085;
}