/* src/components/Header.css */

.plataforma-header.modern-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #1b2a49;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 0px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header-logo {
    width: 60px;
    height: auto;
    margin-right: 20px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.company-info {
    text-align: left;
}

.company-name {
    font-size: 32px;
    margin: 0;
    color: #f1f1f1;
}

.company-slogan {
    font-size: 16px;
    margin: 5px 0 0 0;
    color: #a6b1d4;
}

.menu-horizontal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.menu-horizontal a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    background-color: transparent;
}

.menu-horizontal a:hover {
    background-color: #ff9f43;
    color: #1b2a49;
}

/* Responsive header */
@media (max-width: 768px) {
    .company-name {
        font-size: 24px;
    }

    .company-slogan {
        font-size: 14px;
    }

    .menu-horizontal a {
        font-size: 14px;
        padding: 6px 12px;
    }

    .header-logo {
        width: 50px;
    }
}
