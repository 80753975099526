.plataforma-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    background: #f4f7f6;
}

.plataforma-header.modern-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #1b2a49;
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.header-logo {
    width: 60px;
    height: auto;
    margin-right: 20px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.company-info {
    text-align: left;
}

.company-name {
    font-size: 32px;
    margin: 0;
    color: #f1f1f1;
}

.company-slogan {
    font-size: 16px;
    margin: 5px 0 0 0;
    color: #a6b1d4;
}

.menu-horizontal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.menu-horizontal a {
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    background-color: transparent;
}

.menu-horizontal a:hover {
    background-color: #ff9f43;
    color: #1b2a49;
}

.plataforma-content {
    display: flex;
    flex: 1;
    padding: 20px;
}

.sidebar {
    width: 30%;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-right: 20px;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
}

.search-filters {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.search-bar {
    width: 70%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #3a6351;
    border-radius: 5px;
    outline: none;
    transition: border 0.3s;
}

.search-bar:focus {
    border-color: #8DC63F;
}

.search-buttons img {
    width: 40px;
    height: 30px;
    cursor: pointer;
    transition: transform 0.3s, filter 0.3s;
    filter: invert(64%) sepia(21%) saturate(220%) hue-rotate(148deg) brightness(97%) contrast(90%);
}

.search-buttons img:hover {
    transform: scale(1.1);
    filter: invert(50%) sepia(80%) saturate(300%) hue-rotate(90deg) brightness(95%) contrast(80%);
}

.procedimientos-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.procedimientos-list li {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 10px;
    cursor: pointer;
    background: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.procedimientos-list li:hover, .procedimientos-list li.selected {
    background-color: #f5f5f5;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.procedimiento-caja {
    display: flex;
    flex-direction: column;
}

.procedimiento-caja p {
    margin: 2px 0;
    font-size: 14px;
    color: #333;
}

.estado-procedimiento {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: #8DC63F;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 12px;
}

.main-content {
    flex: 1;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
}

.detalle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.detalle-procedimiento, .documentos-procedimiento {
    position: relative;
    width: 48%;
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(135deg, #f8f8f8, #ffffff);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.detalle-procedimiento {
    position: relative;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.detalle-procedimiento h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #8DC63F;
    padding-bottom: 10px;
    text-align: left;
}


.especialidad-globo {
    position: absolute;
    top: 05px; /* Adjust the top value to position it correctly */
    right: 90px; /* Align it to the right of the container */
    background-color: #8DC63F;
    color: white;
    font-weight: bold;
    padding: 12px 12px;
    border-radius: 10px;
    font-size: 14px;
}

.juzgado {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-top: 15px;
}

.numero-procedimiento {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    text-align: center;
    margin-top: 5px;
}

.fechas-procedimiento {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.fecha-item {
    flex: 1;
}

.fecha-titulo {
    font-weight: bold;
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
}

.detalles-otros {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.detalles-otros p {
    margin: 10px 0;
    font-size: 16px;
    color: #555;
}

.detalles-otros p strong {
    color: #333;
}

.documentos-procedimiento h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #8DC63F;
    padding-bottom: 10px;
}

.documentos-procedimiento ul.documentos-list {
    max-height: 300px;
    overflow-y: auto;
    padding-right: 10px;
}

.documentos-procedimiento ul.documentos-list li {
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.documentos-procedimiento ul.documentos-list li a {
    color: #1b1b1b;
    text-decoration: none;
    transition: color 0.3s;
}

.documentos-procedimiento ul.documentos-list li a:hover {
    color: #8DC63F;
}

.upload-section {
    margin-top: 20px;
}

.upload-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
}

.upload-section input[type="file"] {
    display: none;
}

.upload-section .dropzone {
    padding: 20px;
    border: 2px dashed #8DC63F;
    border-radius: 8px;
    background-color: #f8f8f8;
    text-align: center;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
}

.upload-section .dropzone:hover {
    background-color: #e8f5e9;
}

.upload-error {
    color: #e74c3c;
}

.documentos-procedimiento ul.documentos-list li {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.documentos-procedimiento ul.documentos-list li img.document-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.documentos-procedimiento ul.documentos-list li a {
    color: #1b1b1b;
    text-decoration: none;
    transition: color 0.3s;
    flex-grow: 1;
}

.documentos-procedimiento ul.documentos-list li a:hover {
    color: #8DC63F;
}

.detalle-procedimiento {
    position: relative;
}

.edit-icon-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: transform 0.2s;
}

.edit-icon-top-right:hover {
    transform: scale(1.1);
}

.chat-icon-top-right {
    position: absolute;
    top: 10px;
    right: 50px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: transform 0.2s;
    pointer-events: auto;
}

.chat-icon-top-right:hover {
    transform: scale(1.1);
}


/* DETALLES DEL PROCEDIMIENTO - CSS */

.detalles-otros .detalle-item-ratificacion {
    display: flex;
    flex-direction: column;
    line-height: 20px;
}

.detalles-otros .detalle-item-control-calidad {
    display: flex;
    flex-direction: column;
    line-height: 20px;
}

.detalles-otros {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.detalles-otros .detalle-item {
    background: #f0f4f7;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
}

.detalles-otros .detalle-item strong {
    color: #1b2a49;
    margin-right: 10px;
    font-weight: 600;
}

.download-all-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: transform 0.2s;
}

.download-all-icon:hover {
    transform: scale(1.1);
}


/* PERITOS ASIGNADOS CSS */
.peritos-titulo {
    font-size: 24px;
    color: #333;
    margin-top: 30px;
    margin-bottom: 10px;
    border-bottom: 2px solid #8DC63F;
    padding-bottom: 10px;
    text-align: left;
    display: flex;
    align-items: center; /* Alinea el contenido verticalmente */
}

.peritos-separacion {
    height: 2px;
    background-color: #8DC63F;
    margin-bottom: 20px;
}

.peritos-asignados {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    color: #333;
}

.perito-nombre {
    padding: 10px;
    background: #f0f4f7;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
}

.peritos-icon-top-right {
    position: absolute;
    top: 520px; /* Centra verticalmente respecto al contenedor padre */
    right: 10px; /* Alineado a la derecha del contenedor */
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: transform 0.2s;
}

.peritos-icon-top-right:hover {
    transform: scale(1.1);
}

.document-icon-informe {
    width: 22px;
    height: 22px;
    margin-left: 0px;
    margin-right: 10px;
}


.download-informes-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 15px;
    margin-bottom: 10px;
}

.download-informes-button:hover {
    background-color: #2980b9;
    transform: translateY(-2px);
}

.download-informes-button:active {
    transform: translateY(1px);
}

.detalle-estado-procedimiento {
    font-weight: bold;
    font-size: 18px;
    color: #0b6a23;
    text-align: center;
    margin-top: 5px;
}

.detalle-estado-fechas {
    font-weight: bold;
    font-size: 16px;
    color: #0b186a;
    text-align: center;
    margin-top: 5px;
    margin-bottom: -20px;
}

.peritos-icon-inline {
    width: 25px; /* Ajusta el tamaño del icono según necesites */
    height: 25px;
    margin-left: 15px; /* Espacio entre el texto y el icono */
    cursor: pointer;
    transition: transform 0.2s;
}

.peritos-icon-inline:hover {
    transform: scale(1.1);
}



/* DISPOSITIVOS MOVILES */

@media (max-width: 768px) {
    .plataforma-content {
        flex-direction: column;
        padding: 10px;
    }

    .sidebar {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .main-content {
        width: 100%;
        padding: 10px;
    }

    .detalle-container {
        flex-direction: column;
    }

    .detalle-procedimiento, .documentos-procedimiento {
        width: 100%;
        margin-bottom: 20px;
    }

    .search-filters {
        flex-direction: column;
        gap: 10px;
    }

    .search-bar {
        width: 100%;
    }

    .search-buttons img {
        width: 35px;
        height: 25px;
    }

    .procedimientos-list li {
        font-size: 14px;
    }

    .estado-procedimiento {
        top: 5px;
        left: 5px;
        padding: 3px 7px;
        font-size: 10px;
    }

    .fechas-procedimiento {
        flex-direction: column;
        gap: 15px;
    }

    .especialidad-globo {
        top: 10px;
        right: 20px;
        padding: 8px 10px;
        font-size: 12px;
    }

    .detalle-procedimiento h2 {
        font-size: 20px;
        padding-bottom: 8px;
    }

    .documentos-procedimiento h3 {
        font-size: 20px;
    }

    .upload-section {
        margin-top: 15px;
    }

    .upload-section .dropzone {
        padding: 15px;
    }

    .peritos-titulo {
        font-size: 20px;
        padding-bottom: 8px;
    }

    .perito-nombre {
        font-size: 14px;
    }

    .detalles-otros {
        grid-template-columns: 1fr;
    }

    .detalles-otros .detalle-item {
        padding: 12px;
    }

    .download-informes-button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .documentos-procedimiento ul.documentos-list {
        max-height: 200px;
    }

    .documentos-procedimiento ul.documentos-list li {
        padding: 5px;
    }

    .edit-icon-top-right,
    .chat-icon-top-right,
    .download-all-icon {
        top: 8px;
        right: 8px;
        width: 25px;
        height: 25px;
    }

    .peritos-icon-inline {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
}

.notificar-incomparecencia-button {
    background-color: #e16914;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px; /* un poco de espacio si lo pones al lado de otro botón */
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-bottom: 10px;
}

.notificar-incomparecencia-button:hover {
    background-color: #ab4004;
    transform: translateY(-2px);
}


.incidencia-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 10px;
}

.incidencia-button:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
}



.anular-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.3s;
}

.anular-button:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
}


.document-date {
    margin-left: auto;       /* Para empujarla a la derecha si están en "display: flex" */
    color: #999;
    font-size: 12px;
    font-style: italic;
}


.valoraciones {
    text-align: center;
    justify-content: center;
    background: #f0f4f7;
    padding-top: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    margin-top: 15px;

}

.valoraciones strong {
    color: #1b2a49;
    margin-right: 10px;
    font-weight: 600;
}


/* Plataforma.css */
/* Aplicas la clase enlarged-calendar en tu <Calendar className="enlarged-calendar" ...> */

.enlarged-calendar .ant-picker-calendar-full .ant-picker-content > table {
    table-layout: fixed; /* Para que las celdas respeten la altura y no se “encogan” */
}

/* Fuerza la altura en cada td, y alinea verticalmente arriba */
.enlarged-calendar .ant-picker-calendar-full .ant-picker-content > table > tbody > tr > td {
    height: 140px !important;      /* Ajusta a tu gusto */
    vertical-align: top !important;
    padding: 0 !important;         /* Quita padding extra si lo deseas */
}

/* Hace que la clase .ant-picker-calendar-date ocupe todo el alto de la celda */
.enlarged-calendar .ant-picker-calendar-date {
    display: flex !important;
    flex-direction: column !important;
    height: 100% !important;
    box-sizing: border-box; /* para que padding no rompa el alto */
    padding: 8px;           /* un padding vertical, ajústalo a tu gusto */
}

/* El número del día */
.enlarged-calendar .ant-picker-calendar-date-value {
    margin-bottom: 4px;
}

/* El contenedor donde pintas tu lista (Badges, etc.) que tome el espacio sobrante */
.enlarged-calendar .ant-picker-calendar-date-content {
    flex: 1;              /* ocupa el espacio restante */
    overflow-y: auto;     /* si hay demasiados items, scroll vertical */
    width: 100%;
}


.delete-document-icon {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    cursor: pointer;
}
.delete-document-icon:hover {
    opacity: 0.7;
}