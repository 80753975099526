/* MensajesProcedimientoModal.css */

.chat-message-item {
    margin-bottom: 12px;
    padding: 8px;
    background-color: #9fe397;
    border-radius: 6px;
}

.chat-message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.chat-message-date {
    font-size: 0.85rem;
    color: #999;
}

.chat-message-text {
    white-space: pre-wrap;
}
